import React from "react";
import { FaPhone, FaRegEnvelope, FaInstagram } from "react-icons/fa";
import imgMadeInUsa from "./madeINusa.png";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-box">
        <div className="box1">
          <div className="box">
            <div className="header">Company</div>
            <div className="border"></div>
            <ul className="links-box">
              <li>ECS Pool Panels Inc.</li>
              <li>
                <a
                  href="https://goo.gl/maps/HRHxDoohzCP1RMSk6"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  630 Main Street
                </a>
              </li>
              <li className="address2">
                <a
                  href="https://goo.gl/maps/HRHxDoohzCP1RMSk6"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Westbury,NY 11590
                </a>
              </li>
              <li>
                <div className="img-box">
                  <img src={imgMadeInUsa} alt="" />
                </div>
              </li>
            </ul>
          </div>
          <div className="box">
            <div className="header">Contact Us</div>
            <div className="border"></div>
            <ul className="links-box">
              <li className="contacts">
                <a href="tel:+1718 456-4725">
                  <div className="icon-box">
                    <FaPhone />
                  </div>
                  +1 (718) 456-4725
                </a>
              </li>
              <li className="contacts">
                <a href="mailto:sales@ecspoolpanels.com">
                  <div className="icon-box">
                    <FaRegEnvelope />
                  </div>
                  sales@ecspoolpanels.com
                </a>
              </li>
              <li className="contacts">
                <a
                  href="https://www.instagram.com/ecspoolpanelsinc/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="icon-box">
                    <FaInstagram />
                  </div>
                  Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="box2">
          <div className="last">All rights reserved ©2020</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
