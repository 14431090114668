import React from 'react';
import MadeInUSA from '../footer/madeINusa.png';


function Section() {



    return (
        <section className='section-container'>
            <div className='section-box'>
                <div className='text'>
                    Our products are proudly fabricated in NY, built with the highest grade, zinc-coated, galvanized steel, G235 and ZAM Steel.
                </div>
                <div className='icon'><img src={MadeInUSA}/>
                </div>
                
            </div>
        </section>
    )
}

export default Section;