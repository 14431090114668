const memberOfData = [
  {
    img: "./img/memberOf/phta_text_blue.png",
    website: "https://www.phta.org/",
  },
  //   {
  //     img: "./img/memberOf/phta_text_w.png",
  //     website: "https://www.phta.org/",
  //   },
  {
    img: "./img/memberOf/nespa.png",
    website: "https://www.nespapool.org/",
  },
];

export default memberOfData;
