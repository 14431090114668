import React from 'react';
import { FaPhone} from "react-icons/fa";
import { motion } from "framer-motion";

function Widget() {

    return (
        <motion.div
            className='widget-container'
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 2, duration: 0.4, ease: "easeOut" }}
        >
        {/* <div className='widget-container'> */}
        <div className='widget-box'>
            
            <a href="tel:+1718 456-4725"> 
                <FaPhone />
            </a>
        </div>
        {/* </div> */}
        </motion.div>
    )
}

export default Widget;