import React,{useState} from 'react';
import Partners from './Partners';
import { NavHashLink } from 'react-router-hash-link';
import PanelsImg from "./panels_2_xs.png";
import StepsImg from "./steps_xs.png";
import PoolKitsImg from "./pool_kits_2_xs.png";
import HardwareImg from "./hardware_xs.png";
import { BiDownArrowAlt, BiUpArrowAlt } from "react-icons/bi";

function Products() {
    const [poolDropdown, setPoolDropdown] = useState(false);
    const [hardwareDropdown, setHardwareDropdown] = useState(false);
    const [panelsDropdown, setPanelsDropdown] = useState(false);
    const [sbDropdown, setSBDropdown] = useState(false);

    return (
        <section className='products-container' id='products'>
            <div className='section-title'>PRODUCTS</div>
            <div className='box'>
                <div className='products-box'>
                    <div className='product-box'>
                        <div className='category' onClick={() => setPanelsDropdown(!panelsDropdown)}>
                            <div className='title'>Steel Panels</div> 
                            <div className="icon-box">
                                {panelsDropdown ? <BiUpArrowAlt /> : <BiDownArrowAlt />}
                            </div>
                        </div>
                        {panelsDropdown ?
                        <div className='dropdown-box'> 
                            <div className='img-box'>
                                <img src={PanelsImg} alt=""/>
                            </div>
                            <ul className='dropdown'>
                                <li>G235 Steel Panels</li>
                                <li>ZAM Steel Panels</li>
                                <li> Light Panels 10" Nitch</li>
                                <li> Widemouth Skimmer Panels</li>
                            </ul> 
                        </div>
                            : ''}

                    </div>
                    <div className='product-box'>
                        <div className='category' onClick={() => setHardwareDropdown(!hardwareDropdown)}>
                            <div className='title'>Hardware</div> 
                            <div className="icon-box">
                                {hardwareDropdown ? <BiUpArrowAlt /> : <BiDownArrowAlt />}</div>
                            </div>
                        {hardwareDropdown ?
                            <div className='dropdown-box'>
                                <div className='img-box'>
                                    <img src={HardwareImg} alt="" />
                                </div>
                                <ul className='dropdown'>
                                    <li>Amoray Light</li>
                                    <li>Aluminium Liner Receiver (Top-mount)</li>
                                    <li>Turnbuckles</li>
                                    <li>A-frames</li>
                                    <li>Stake drives</li>
                                    <li>Stake pads</li>
                                    <li>Steps Supports</li>
                                    <li>3/8 Bolt Assembly</li>
                                    <li>1" Tek screws</li>
                                </ul>
                            </div>
                            : ''}
                    </div>
                    <div className='product-box'>
                        <div className='category' onClick={() => setPoolDropdown(!poolDropdown)}>
                            <div className='title'>Custom Pool and Spa Kits</div>
                            <div className="icon-box">
                             {poolDropdown ? <BiUpArrowAlt /> : <BiDownArrowAlt />}
                            </div>
                        </div>
                        {poolDropdown ?
                            <div className='dropdown-box'>
                                <div className='img-box'>
                                    <img src={PoolKitsImg} alt="" />
                                </div>
                                <ul className='dropdown'>
                                    <li>Rectangular 90º Corners</li>
                                    <li>Rectangular 6" Rad. Corners</li>
                                    <li>True L</li>
                                    <li>Lazy L</li>
                                    <li>Grecian</li>
                                    <li>Gothic</li>
                                    <li>Lap Pools</li>
                                    <li>Roman End</li>
                                    <li>Double Roman End</li>
                                    <li>T-Shape</li>
                                    <li>10'x10' Spa Corner Steps</li>
                                    <li>7'x7' Spa Corner Steps</li>
                                </ul>
                            </div>
                            : ''}
                    </div>
                    <div className='product-box'>
                        <div className='category' onClick={() => setSBDropdown(!sbDropdown)}>
                            <div className='title'>Steps and Benches</div>
                            <div className="icon-box">
                                {sbDropdown ? <BiUpArrowAlt /> : <BiDownArrowAlt />}
                            </div>
                        </div>
                        {sbDropdown ?
                            <div className='dropdown-box'>
                                <div className='img-box'>
                                    <img src={StepsImg} alt="" />
                                </div>
                                <ul className='dropdown'>
                                    <li>Stadium Steps</li>
                                    <li>Corner Steps</li>
                                    <li>Wedding Cake Steps</li>
                                    <li>Side Steps</li>
                                    <li>Side Steps 1' Rad. Corners</li>
                                    <li>Corner Steps and Bench</li>
                                    <li>Stadium Steps and Bench</li>
                                    <li>Double Sided Bench</li>
                                    <li>Corner Bench</li>
                                    <li>Sunshelf (Tanning Ledge)</li>
                                </ul>
                            </div>
                            : ''}
                    </div>
                </div>
            </div>

            <button>
                <NavHashLink className='' smooth to="/#contact" >
                    Get a Quote
                </NavHashLink>
            </button>
            <Partners />

        </section>
    )
}

export default Products;