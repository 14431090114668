const partnersData = [
  {
    img: "./img/partners/amoray-logo-1200px_optimized.png",
    // website: "https://www.amoraylighting.com/",
  },
  {
    img: "./img/partners/cropped-stegmeier_llc_mark_RGB_blue_0220.1.png",
    // website: "https://www.stegmeier.com/",
  },
  // { img: "./img/partners/erlin_logo.jpeg", website: 'https://erlinsteel.com/' },
  {
    img: "./img/partners/zamlogo.png",
    website: "https://www.wheeling-nipponsteel.com/",
  },
  {
    img: "./img/partners/PV-Horizontal.png",
    // website: "https://palmettovermiculite.com/",
  },
  {
    img: "./img/partners/Charlotte.png",
    // website: "https://www.charlottepipe.com/",
  },
  {
    img: "./img/partners/poolform.png",
    // website: "https://www.charlottepipe.com/",
  },
];

export default partnersData;
