import React, { useEffect } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import aboutData from "../../aboutData";
import { motion } from "framer-motion";
import SectionLogos from "../sectionLogos/SectionLogos";

const About = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="about-container">
      <div className="about-company-box">
        <motion.div
          className="about-box"
          initial={{ x: -5, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.3, ease: "easeOut" }}
        >
          <div className="name">ECS POOL PANELS</div>
          <div className="info">
            ECS Pool Panels Inc. was established in 2020, to satisfy the high
            demands for Swimming Pools. Located in Maspeth, NY, ECS Pool Panels,
            has filled the void for all pool contractors, within the five
            boroughs of NYC, and the entire Tri-State area. As a small, local
            business, our staff provides one-on-one service, to ensure customer
            satisfaction.
            <br />
            <br />
            Our fabrication facility offers pool contractors the flexibility to
            custom design any size steel pool and jacuzzi/spa with the shortest
            lead-time in the industry. We also stock pool panels from 1’ to 8’,
            stadium steps, corner steps, sundecks, benches, turn buckles,
            A-frames, drive stakes, aluminum pool liner track and miscellaneous
            installation hardware. From design to fabrication, ECS Pool Panels
            delivers a quality product, using top grade materials, made in the
            USA, with the highest level of craftsmanship.
          </div>
        </motion.div>
        <div className="images-container">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
          >
            <Masonry>
              {aboutData.map((element, idx) => (
                <div className="img-box" key={idx}>
                  <img src={element} alt="" />
                </div>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </div>
        <SectionLogos />
      </div>
    </div>
  );
};

export default About;
