import React from "react";
import Entry from "../entry/Entry";
import Section from "../section/Section";
import Section2 from "../section/Section2";
import Gallery from "../gallery/Gallery";
import Products from "../products/Products";
import Form from "../form/Form";
import Map from "../map/Map";
import SectionLogos from "../sectionLogos/SectionLogos";

function Splash() {
  return (
    <>
      <Entry />
      <div className="background">
        <Section />
        <Products />
        <Section2 />
        <Gallery />
        <SectionLogos />
        <Form />
        <Map />
      </div>
    </>
  );
}

export default Splash;
