import React from 'react';
import { BiWater } from "react-icons/bi";


function Section2() {



    return (
        <section className='section2-container'>
            <div className='section-box'>
                <div className='text'>
                    Our fabrication facility offers pool contractors the flexibility to custom design any size steel pool and jacuzzi/spa with the shortest lead-time in the industry
                </div>
                <div className='icon'><BiWater /></div>
                
            </div>
        </section>
    )
}

export default Section2;