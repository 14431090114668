const aboutData = [
    "./img/about/1.jpg", 
    "./img/about/2.jpg",
    "./img/about/3.jpg",
    "./img/about/4.jpg",
    "./img/about/5.jpg",
    "./img/about/6.jpg",
    "./img/about/7.jpg",
    "./img/about/8.jpg",
    // "./img/about/9.jpg",
    "./img/about/10.jpg",
]
 
export default aboutData