import React, { useEffect, useState } from "react";
// import { BsArrowRightShort } from "react-icons/bs";
import { CgMailForward } from "react-icons/cg";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";

function Form() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isMaterial1, setIsMaterial1] = useState(false);
  const [isMaterial2, setIsMaterial2] = useState(false);
  const [isMaterial3, setIsMaterial3] = useState(false);
  const [isMaterial4, setIsMaterial4] = useState(false);
  const [isMaterial5, setIsMaterial5] = useState(false);
  const [isMaterial6, setIsMaterial6] = useState(false);
  const [message, setMessage] = useState("");
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    if (name !== "" && email !== "" && message !== "" && phone !== "") {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, [name, email, message, phone]);

  return (
    <div className="form-container" id="contact">
      <div className="section-title">CONTACT US</div>
      <div className="form-box">
        <form
          name="Contact-Us-Form"
          method="post"
          data-netlify="true"
          onSubmit="submit"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="Contact-Us-Form" />
          <div hidden>
            <input name="bot-field" />
          </div>

          <input
            className="input-field"
            type="text"
            name="Name"
            placeholder="your name*"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          <input
            className="input-field"
            type="email"
            name="Email"
            placeholder="your@email.com*"
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <input
            className="input-field"
            type="text"
            name="Phone_number"
            placeholder="phone number*"
            onChange={(e) => setPhone(e.target.value)}
            required
          />

          <textarea
            name="Message"
            rows="3"
            placeholder="message*"
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>

          <label className="materials-title">Material of Interest:</label>

          <div className="materials">
            <label className="checkbox-box">
              <div className="box">
                <Checkbox
                  className=""
                  type="checkbox"
                  value="YES"
                  name="Steel_panels"
                  onChange={(event) => {
                    setIsMaterial1(event.target.checked);
                  }}
                />
              </div>
              <div className="material-name">Steel Panels</div>
            </label>

            <label className="checkbox-box">
              <div className="box">
                <Checkbox
                  className=""
                  type="checkbox"
                  value="YES"
                  name="Hardware"
                  onChange={(event) => {
                    setIsMaterial2(event.target.checked);
                  }}
                />
              </div>
              <div className="material-name">Hardware</div>
            </label>

            <label className="checkbox-box">
              <div className="box">
                <Checkbox
                  className=""
                  type="checkbox"
                  value="YES"
                  name="Pool_and_spa_kits"
                  onChange={(event) => {
                    setIsMaterial3(event.target.checked);
                  }}
                />
              </div>
              <div className="material-name">Custom Pool and Spa Kits</div>
            </label>

            <label className="checkbox-box">
              <div className="box">
                <Checkbox
                  className=""
                  type="checkbox"
                  value="YES"
                  name="Steps_and_Benches"
                  onChange={(event) => {
                    setIsMaterial4(event.target.checked);
                  }}
                />
              </div>
              <div className="material-name">Steps and Benches</div>
            </label>

            <label className="checkbox-box">
              <div className="box">
                <Checkbox
                  className=""
                  type="checkbox"
                  value="YES"
                  name="Amoray_Lights"
                  onChange={(event) => {
                    setIsMaterial5(event.target.checked);
                  }}
                />
              </div>
              <div className="material-name">Amoray Lights</div>
            </label>

            <label className="checkbox-box">
              <div className="box">
                <Checkbox
                  className=""
                  type="checkbox"
                  value="YES"
                  name="Stegmeier"
                  onChange={(event) => {
                    setIsMaterial6(event.target.checked);
                  }}
                />
              </div>
              <div className="material-name">Stegmeier</div>
            </label>
          </div>

          <button
            type="submit"
            disabled={isFilled ? false : true}
            className={isFilled ? "active" : ""}
          >
            Send
            <span>
              <CgMailForward />
            </span>
          </button>
        </form>
      </div>
    </div>
  );
}

export default Form;
