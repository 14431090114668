import React from "react";
import { BsBoxArrowUpRight } from "react-icons/bs";
import pinImg from "./Asset1.png";

function Map() {
  return (
    <section className="map-container">
      <div className="overlay">
        <div className="img-box">
          <img src={pinImg} alt="" />
        </div>
      </div>
      <a
        className="overlay2"
        href="https://goo.gl/maps/HRHxDoohzCP1RMSk6"
        rel="noopener noreferrer"
        target="_blank"
      >
        <div className="icon-box">
          <span>Open Google Maps </span>
          <div className="icon">
            <BsBoxArrowUpRight />
          </div>
        </div>
      </a>
    </section>
  );
}

export default Map;
