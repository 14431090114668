import React from "react";
import memberOfData from "../../memberOfData";

const sectionLogos = () => {
  return (
    <section className="section-logos-container">
      <div className="section-logos-title">MEMBER OF</div>
      <div className="section-logos-box">
        {memberOfData.map((element, idx) => {
          return (
            <div className="img-box" key={idx}>
              <a
                href={element.website}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={element.img} alt="" />
              </a>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default sectionLogos;
