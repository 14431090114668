import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import Navbar from "./navbar/Navbar";
import Widget from "./widget/Widget";
import Splash from "./splash/Splash";
import About from "./about/About";
import Warranty from "./warranty/Warranty";
import Footer from "./footer/Footer";
import AddressModal from "./addressModal/AddressModal";
import SnackbarProvider from "react-simple-snackbar";

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const handleClose = () => setIsModalOpen(false);

  return (
    <div className="App">
      <Navbar />
      <Widget />
      <SnackbarProvider>
        <Switch>
          <Route exact path="/" component={Splash} />
          <Route exact path="/warranty" component={Warranty} />
          <Route exact path="/about" component={About} />
        </Switch>
      </SnackbarProvider>
      <Footer />
      <AddressModal handleClose={handleClose} isOpen={isModalOpen} />
    </div>
  );
};

export default App;
