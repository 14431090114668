import React from 'react';
import galleryData from '../../galleryData';
import Carousel from "react-multi-carousel";
import { BsBoxArrowUpRight } from "react-icons/bs";



function Gallery() {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 550 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 550, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };


    return (
        <section className='gallery-container' id='gallery'>
            <div className='section-title'>GALLERY</div>
            <div className='gallery-box'>
                <Carousel
                    swipeable
                    draggable={false}
                    showDots
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite
                    // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                    // autoPlaySpeed={1000}
                    keyBoardControl
                    // customTransition="all .5"
                    transitionDuration={1000}
                    containerClass="carousel-container"
                    // removeArrowOnDeviceType={["tablet", "mobile"]}
                    // deviceType={this.props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    slidesToSlide={1} 
                >
                    {galleryData.map((image, idx)=>(
                        <div className='carousel-img-box' key={idx}><img src={image} alt=""/></div>
                    ))}
                </Carousel>
           
            </div>
            <div className='redirect-box'>
                <a href="https://www.instagram.com/ecspoolpanelsinc/" rel='noopener noreferrer' target="_blank">
                    <div className='title'>
                            See more @ecspoolpanelsinc
                    </div>
                    <div className='icon-box'>
                        <BsBoxArrowUpRight/>
                    </div>
                </a>
            </div>
        </section>
    )
}

export default Gallery;