import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import mainLogo from "./new_ECS_LOGO_higherresolution.png";
import mainLogoWhite from "./new_ECS_LOGO_LIGHT_highterresolution.png";
import { IoMenuOutline, IoCloseOutline } from "react-icons/io5";
import { FaPhone, FaRegEnvelope, FaInstagram } from "react-icons/fa";
import { motion } from "framer-motion";

const Navbar = () => {
  const [showLinks, SetShowLinks] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape" && showLinks) {
        SetShowLinks(false);
      }
    };
    document.addEventListener("keydown", close);

    return () => document.removeEventListener("keydown", close);
  });

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 768;
        if (ismobile !== isMobile) {
          setIsMobile(ismobile);
        }
        if (!ismobile && window.innerWidth >= 768) {
          SetShowLinks(false);
        }
      },
      false
    );
  }, [isMobile]);

  return (
    <>
      <div className="nav-back">
        <motion.div
          className="nav-container"
          id="to-top"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.8, ease: "easeOut" }}
        >
          <nav className="nav-container" id="to-top">
            <div
              className="logo-box"
              onClick={() => {
                scrollToTop();
                SetShowLinks(false);
              }}
            >
              <Link to="/">
                <img src={mainLogo} alt="" />
              </Link>
            </div>
            <div className="links-box">
              <div className="link">
                <NavHashLink
                  className=""
                  to="/#products"
                  smooth
                  activeclassname="selected"
                  activeStyle={{ borderBottom: "2px solid #00ABC8" }}
                >
                  Products
                </NavHashLink>
              </div>
              <div className="link">
                <NavHashLink
                  className=""
                  to="/#gallery"
                  activeclassname="selected"
                  activeStyle={{ borderBottom: "2px solid #00ABC8" }}
                >
                  Gallery
                </NavHashLink>
              </div>

              <div className="link">
                <NavHashLink
                  className=""
                  to="/warranty"
                  activeclassname="selected"
                  activeStyle={{ borderBottom: "2px solid #00ABC8" }}
                >
                  Warranty
                </NavHashLink>
              </div>

              <div className="link">
                <NavHashLink
                  className=""
                  to="/about"
                  activeclassname="selected"
                  activeStyle={{ borderBottom: "2px solid #00ABC8" }}
                >
                  About
                </NavHashLink>
              </div>
              <div className="link">
                <NavHashLink className="link-contact" smooth to="/#contact">
                  Contact
                </NavHashLink>
              </div>
            </div>
            <div
              className="burger-box"
              onClick={() => SetShowLinks(!showLinks)}
            >
              <IoMenuOutline />
            </div>
          </nav>
        </motion.div>
      </div>
      <div className={`links-box-dropdown ${showLinks ? "open" : ""}`}>
        <div className="top-container">
          <div className="logo-box" onClick={() => SetShowLinks(false)}>
            <img src={mainLogoWhite} alt="" />
          </div>
          <div className="burger-box" onClick={() => SetShowLinks(false)}>
            <IoCloseOutline />
          </div>
        </div>
        <div className="box">
          <div className="link">
            <NavHashLink
              className=""
              to="/#products"
              smooth
              onClick={() => SetShowLinks(!showLinks)}
            >
              Products
            </NavHashLink>
          </div>
          <div className="link">
            <NavHashLink
              className=""
              smooth
              to="/#gallery"
              onClick={() => SetShowLinks(!showLinks)}
            >
              Gallery
            </NavHashLink>
          </div>
          <div className="link">
            <NavHashLink
              className=""
              to="/warranty"
              onClick={() => SetShowLinks(!showLinks)}
            >
              Warranty
            </NavHashLink>
          </div>
          <div className="link">
            <NavHashLink
              className=""
              to="/about"
              onClick={() => SetShowLinks(!showLinks)}
            >
              About
            </NavHashLink>
          </div>
          <div className="link">
            <NavHashLink
              className=""
              smooth
              to="/#contact"
              onClick={() => SetShowLinks(!showLinks)}
            >
              Contact
            </NavHashLink>
          </div>
        </div>
        <div className="contact-us-box">
          <div className="contact">
            <a href="tel:+1718 456-4725">
              <FaPhone />
            </a>
          </div>
          <div className="contact">
            <a href="mailto:sales@ecspoolpanels.com">
              <FaRegEnvelope />
            </a>
          </div>
          <div className="contact">
            <a
              href="https://www.instagram.com/ecspoolpanelsinc/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
