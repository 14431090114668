import React, { useEffect } from "react";
import addressImg from "./large.jpeg";
import { IoCloseOutline } from "react-icons/io5";

const AddressModal = ({ handleClose, isOpen }) => {
  useEffect(() => {
    const close = (e) => {
      if (e.key === "Escape") {
        handleClose();
      }
    };
    document.addEventListener("keydown", close);
    return () => document.removeEventListener("keydown", close);
  });

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-container" onClick={handleClose}>
      <div className="modal-box" onClick={(e) => e.stopPropagation()}>
        <div className="image-wrapper">
          <div className="icon" onClick={handleClose}>
            <IoCloseOutline />
          </div>
          <img src={addressImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default AddressModal;
