import React, { useEffect, useState } from "react";
import WarrantyForm from "../form/WarrantyForm";
import ECSPoolPanelsWarranty from "./ECSPoolPanelsWarranty.jpg";
import { ClipLoader } from "react-spinners";

const Warranty = () => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoadingToggle = () => setIsLoading(false);
  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="warranty-container">
      <div className="warranty-wrapper">
        <div className="warranty-image-wrapper">
          <div className="name">Warranty Information</div>
          {isLoading && (
            <div className="spinner-wrapper">
              <ClipLoader color="black" />
            </div>
          )}
          <img
            src={ECSPoolPanelsWarranty}
            alt=""
            onLoad={handleLoadingToggle}
            style={{ display: isLoading ? "none" : "block" }}
          />
        </div>
        <div className="warranty-form-box">
          <div className="warranty-form-name">Warranty Form</div>
          <WarrantyForm />
        </div>
      </div>
    </div>
  );
};

export default Warranty;
