import React from "react";
import partnersData from "../../partnersData";

const Partners = () => {
  return (
    <section className="partners-container">
      <div className="section-title">PROUDLY DISTRIBUTING</div>
      <div className="partners-box">
        {partnersData.map((partner, idx) => {
          return (
            <div className="img-box" key={idx}>
              <a
                href={partner?.website && "/"}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={partner.img} alt="" />
              </a>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Partners;
