import React, { useState, useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Checkbox from "rc-checkbox";
import { CgMailForward } from "react-icons/cg";
import { ClipLoader } from "react-spinners";
import { useSnackbar } from "react-simple-snackbar";

const FormEmailJs = () => {
  const [isErrorWhenFormSent, setIsErrorWhenFormSent] = useState(false);
  const options = {
    style: {
      backgroundColor: isErrorWhenFormSent ? "#f44336" : "#388e3c",
    },
  };
  const form = useRef();
  const [openSnackbar] = useSnackbar(options);

  const [isOwnerName, setIsOwnerName] = useState("");
  const [isOwnerAddress, setIsOwnerAddress] = useState("");
  const [isOwnerCity, setIsOwnerCity] = useState("");
  const [isOwnerState, setIsOwnerState] = useState("");
  const [isOwnerZipcode, setIsOwnerZipcode] = useState("");
  const [isOwnerCountry, setIsOwnerCountry] = useState("");
  const [isOwnerEmail, setIsOwnerEmail] = useState("");
  const [isOwnerPhone, setIsOwnerPhone] = useState("");

  const [isCompanyName, setIsCompanyName] = useState("");
  const [isCompanyAddress, setIsCompanyAddress] = useState("");
  const [isCompanyCity, setIsCompanyCity] = useState("");
  const [isCompanyState, setIsCompanyState] = useState("");
  const [isCompanyZipcode, setIsCompanyZipcode] = useState("");
  const [isCompanyCountry, setIsCompanyCountry] = useState("");
  const [isCompanyEmail, setIsCompanyEmail] = useState("");
  const [isCompanyPhone, setIsCompanyPhone] = useState("");

  const [isPoolSize, setIsPoolSize] = useState("");
  const [isCustomFeature, setIsCustomFeature] = useState("");
  const [isPurchaseDate, setIsPurchaseDate] = useState("");
  const [isInstallationDate, setIsInstallationDate] = useState("");

  const [isStepType1, setIsStepType1] = useState(false);
  const [isStepType2, setIsStepType2] = useState(false);

  const [isInitials, setIsInitials] = useState("");

  const [isFilledForm, setIsFilledForm] = useState(false);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (
      isOwnerName !== "" &&
      isOwnerAddress !== "" &&
      isOwnerCity !== "" &&
      isOwnerState !== "" &&
      isOwnerZipcode !== "" &&
      isOwnerCountry !== "" &&
      isOwnerEmail !== "" &&
      isCompanyName !== "" &&
      isCompanyAddress !== "" &&
      isCompanyCity !== "" &&
      isCompanyState !== "" &&
      isCompanyZipcode !== "" &&
      isCompanyCountry !== "" &&
      isCompanyEmail !== "" &&
      isPurchaseDate !== "" &&
      isInstallationDate !== "" &&
      isInitials !== ""
    ) {
      setIsFilledForm(true);
    } else {
      setIsFilledForm(false);
    }
  }, [
    isOwnerName,
    isOwnerAddress,
    isOwnerCity,
    isOwnerState,
    isOwnerZipcode,
    isOwnerCountry,
    isOwnerEmail,
    isCompanyName,
    isCompanyAddress,
    isCompanyCity,
    isCompanyState,
    isCompanyZipcode,
    isCompanyCountry,
    isCompanyEmail,
    isPurchaseDate,
    isInstallationDate,
    isInitials,
  ]);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSending(true);

    emailjs
      .sendForm(
        process.env.REACT_APP_YOUR_SERVICE_ID,
        process.env.REACT_APP_YOUR_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_YOUR_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          clearForm();
          setIsSending(false);
          setIsErrorWhenFormSent(false);

          openSnackbar("Success!");
        },
        (error) => {
          console.log(error.text);
          setIsSending(false);
          setIsErrorWhenFormSent(true);
          openSnackbar("Error. Please try again...");
        }
      );
  };

  const clearForm = () => {
    setIsOwnerName("");
    setIsOwnerAddress("");
    setIsOwnerCity("");
    setIsOwnerState("");
    setIsOwnerZipcode("");
    setIsOwnerCountry("");
    setIsOwnerEmail("");
    setIsOwnerPhone("");
    setIsCompanyName("");
    setIsCompanyAddress("");
    setIsCompanyCity("");
    setIsCompanyState("");
    setIsCompanyZipcode("");
    setIsCompanyCountry("");
    setIsCompanyEmail("");
    setIsCompanyPhone("");
    setIsPurchaseDate("");
    setIsInstallationDate("");
    setIsPoolSize("");
    setIsCustomFeature("");
    setIsStepType1(false);
    setIsStepType2(false);
    setIsInitials("");
  };

  return (
    <form ref={form} onSubmit={sendEmail}>
      <div className="sub-section">Owner Information*</div>

      <input
        type="text"
        name="user_name"
        className="input-field"
        placeholder="Name*"
        value={isOwnerName}
        onChange={(e) => setIsOwnerName(e.target.value)}
      />

      <input
        type="text"
        name="user_address"
        className="input-field"
        placeholder="Address*"
        value={isOwnerAddress}
        onChange={(e) => setIsOwnerAddress(e.target.value)}
      />

      <div className="row-wrapper">
        <input
          type="text"
          name="user_city"
          className="item30"
          placeholder="City*"
          value={isOwnerCity}
          onChange={(e) => setIsOwnerCity(e.target.value)}
        />

        <input
          type="text"
          name="user_state"
          className="item30"
          placeholder="State*"
          value={isOwnerState}
          onChange={(e) => setIsOwnerState(e.target.value)}
        />

        <input
          type="number"
          name="user_zip"
          className="item30"
          placeholder="Zip Code*"
          min="0"
          value={isOwnerZipcode}
          onChange={(e) => setIsOwnerZipcode(e.target.value)}
        />
      </div>

      <input
        type="text"
        name="user_country"
        className="input-field"
        placeholder="Country*"
        value={isOwnerCountry}
        onChange={(e) => setIsOwnerCountry(e.target.value)}
      />

      <div className="row-wrapper">
        <input
          type="email"
          name="user_email"
          className="item50"
          placeholder="Email*"
          value={isOwnerEmail}
          onChange={(e) => setIsOwnerEmail(e.target.value)}
        />

        <input
          type="tel"
          name="user_phone"
          className="item50"
          placeholder="Phone"
          value={isOwnerPhone}
          onChange={(e) => setIsOwnerPhone(e.target.value)}
        />
      </div>
      <div className="divider" />

      <div className="sub-section">Installation Contractor*</div>
      <input
        type="text"
        name="user_company_name"
        className="input-field"
        placeholder="Name*"
        value={isCompanyName}
        onChange={(e) => setIsCompanyName(e.target.value)}
      />

      <input
        type="text"
        name="user_company_address"
        className="input-field"
        placeholder="Address*"
        value={isCompanyAddress}
        onChange={(e) => setIsCompanyAddress(e.target.value)}
      />

      <div className="row-wrapper">
        <input
          type="text"
          name="user_company_city"
          className="item30"
          placeholder="City*"
          value={isCompanyCity}
          onChange={(e) => setIsCompanyCity(e.target.value)}
        />

        <input
          type="text"
          name="user_company_state"
          className="item30"
          placeholder="State*"
          value={isCompanyState}
          onChange={(e) => setIsCompanyState(e.target.value)}
        />

        <input
          type="number"
          name="user_company_zip"
          className="item30"
          placeholder="Zip Code*"
          min="0"
          value={isCompanyZipcode}
          onChange={(e) => setIsCompanyZipcode(e.target.value)}
        />
      </div>

      <input
        type="text"
        name="user_company_country"
        className="input-field"
        placeholder="Country*"
        value={isCompanyCountry}
        onChange={(e) => setIsCompanyCountry(e.target.value)}
      />

      <div className="row-wrapper">
        <input
          type="email"
          name="user_company_email"
          className="item50"
          placeholder="Email*"
          value={isCompanyEmail}
          onChange={(e) => setIsCompanyEmail(e.target.value)}
        />

        <input
          type="tel"
          name="user_company_phone"
          className="item50"
          placeholder="Phone"
          value={isCompanyPhone}
          onChange={(e) => setIsCompanyPhone(e.target.value)}
        />
      </div>
      <div className="divider" />

      <label className="sub-sub-section">Steps Type</label>
      <div className="row-wrapper">
        <label className="checkbox-box">
          <div className="box">
            <Checkbox
              checked={isStepType1}
              type="checkbox"
              value="Yes"
              name="user_stadium_steps"
              onChange={(event) => setIsStepType1(event.target.checked)}
            />
          </div>
          <div className="material-name">Stadium Steps</div>
        </label>

        <label className="checkbox-box">
          <div className="box">
            <Checkbox
              checked={isStepType2}
              type="checkbox"
              value="Yes"
              name="user_corner_steps"
              onChange={(event) => setIsStepType2(event.target.checked)}
            />
          </div>
          <div className="material-name">Corner Steps</div>
        </label>
      </div>

      <label className="sub-sub-section" />
      <div className="row-wrapper">
        <input
          type="text"
          name="user_pool_size"
          className="item50"
          placeholder="Pool size"
          value={isPoolSize}
          onChange={(e) => setIsPoolSize(e.target.value)}
        />

        <input
          type="text"
          name="user_custom_feature"
          className="item50"
          placeholder="Custom feature"
          value={isCustomFeature}
          onChange={(e) => setIsCustomFeature(e.target.value)}
        />
      </div>
      <div className="date-row-wrapper">
        <div className="date-wrapper">
          <label className="sub-sub-section">Purchase Date*</label>
          <input
            type="date"
            name="user_purchase_date"
            className="item50"
            placeholder="Purchase date"
            value={isPurchaseDate}
            onChange={(e) => setIsPurchaseDate(e.target.value)}
          />
        </div>

        <div className="date-wrapper">
          <label className="sub-sub-section">Installation Date*</label>
          <input
            type="date"
            name="user_installation_date"
            className="item50"
            placeholder="Installation date"
            value={isInstallationDate}
            onChange={(e) => setIsInstallationDate(e.target.value)}
          />
        </div>
      </div>

      <div className="divider" />

      <label className="sub-sub-section">
        Acknowledgement (I have read and understand the terms of the warranty).
      </label>
      <input
        type="text"
        name="user_initials"
        className="item50 initials"
        placeholder="Signature*"
        value={isInitials}
        onChange={(e) => setIsInitials(e.target.value)}
      />
      <div className="divider" />

      <div className="buttons-wrapper">
        <button
          value="Send"
          disabled={!isFilledForm}
          className={isFilledForm ? "send-button active" : "send-button"}
        >
          {isSending ? (
            <ClipLoader color="black" size={24} />
          ) : (
            <>
              Send
              <span>
                <CgMailForward />
              </span>
            </>
          )}
        </button>

        <div onClick={clearForm} className="clear-button">
          Clear Form
        </div>
      </div>
    </form>
  );
};

export default FormEmailJs;
