import React from "react";
import { motion } from "framer-motion";
import { NavHashLink } from "react-router-hash-link";

const Entry = () => {
  return (
    <section className="entry-container">
      <div className="img-box"></div>
      <motion.div
        className="info-box"
        initial={{ x: -25, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.8, ease: "easeOut" }}
      >
        <div className="name">ECS POOL PANELS</div>
        <div className="sub-name">
          We specialize in the fabrication of steel pool panels and custom
          design pools, helping you achieve your best work yet with steel of the
          highest quality and durability made in the USA.
        </div>
        <button>
          <NavHashLink className="" smooth to="/#contact">
            Let's Start
          </NavHashLink>
        </button>
      </motion.div>

      <div className="right"></div>
    </section>
  );
};

export default Entry;
